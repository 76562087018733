/* Keyframes */
@keyframes loadingCycle {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.button {
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  margin: 0;
}

.button[data-loading='true'] {
  pointer-events: none;
  cursor: default;
  justify-content: center;
}

.button[data-disabled='true'] {
  pointer-events: none;
  user-select: none;
  cursor: default;
}

.centerWrap {
  display: inline-flex;
  align-items: center;
}

.loading {
  animation: loadingCycle 1s linear infinite;
}
