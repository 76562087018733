.chevron {
  width: 20px;
  height: 20px;
  color: var(--violet10);
  transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
}

.chevronTrigger[data-state='open'] > [class*='radix-accordion-chevron-icon'] {
  transform: rotate(180deg);
}
