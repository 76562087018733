.menuItem {
  position: relative;
  color: black;
  display: flex;
  align-items: center;
  user-select: none;
  outline: none;
  cursor: pointer;
}
.menuItem[data-disabled] {
  pointer-events: none;
  cursor: default;
}
