.trigger {
  width: 100%;
  height: inherit;
  min-height: inherit;
  max-height: inherit;
  font-family: inherit;
  background-color: transparent;
  flex: 1;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.triggerContent {
  width: 100%;
}

.trigger[data-disabled] {
  cursor: default;
}
