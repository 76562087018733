.wrap {
  display: flex;
  flex-direction: column;
}

.wrap[data-label-pos='bottom'] {
  flex-direction: column-reverse;
}

.wrap[data-label-pos='left'],
.wrap[data-label-pos='right'] {
  align-items: center;
}

.wrap[data-label-pos='left'] {
  flex-direction: row;
}

.wrap[data-label-pos='right'] {
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.label {
  display: block;
}

.label[for] {
  cursor: pointer;
}

.label[data-disabled='true'] {
  cursor: default;
}
