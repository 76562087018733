.item {
  overflow: hidden;
  margin-top: 1px;
}

.item:first-child {
  margin-top: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.item:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.item:focus-within {
  position: relative;
  z-index: 1;
}
