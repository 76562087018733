.trigger {
  cursor: pointer;
}
.trigger[data-state='open'] {
  cursor: default;
}

.trigger[disabled],
.trigger[data-disabled='true'] {
  cursor: not-allowed;
}
.trigger:focus {
  outline: none;
}
